import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import background from "./background.png";
import Header from "elements/header/Header";
import { ERROR } from "constants/proptypes/errors/errors.js";
import { t } from "locales";
import { toTopPage } from "pages";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  item: {
    textAlign: "center",
  },
  img: {
    width: "100%",
  },
  container: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
  },
}));

const Errors = ({ code, message, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header title={t("templates.errors.title")} />
      <Container maxWidth="md" className={classes.container}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} className={classes.item}>
            <Typography variant="h3" data-test="code">
              {code}
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={4} className={classes.item}>
            <img src={background} alt="error" className={classes.img} />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} className={classes.item}>
            <Typography variant="body1" gutterBottom data-test="message">
              {message}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} className={classes.item}>
            <Typography
              variant="body1"
              gutterBottom
              data-test="additional-message"
            >
              {t("templates.errors.additional.message")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={4} className={classes.item}>
            <Button
              variant="contained"
              data-test="button"
              color="primary"
              className={classes.button}
              onClick={toTopPage}
            >
              {t("templates.errors.button.top")}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Errors.defaultProps = {
  code: "",
  message: "",
};

Errors.propTypes = {
  ...ERROR,
};

export default Errors;
