import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  history,
  urlUtil,
  smartdbUtil,
} from "@dreamarts/baas-common-front-node";
import { confirmAlert } from "react-confirm-alert";
import List from "compositions/list/DocuSignList";
import { t } from "locales";
import Header from "elements/header/Header";

import { DOCU_SIGN } from "constants/proptypes/docuSign";
import { DOCUSIGN_EDIT_PAGE_URL } from "constants/index";
import ACCESSTOKEN_ALERT_OPTIONS from "constants/option/accessToken";
import { toErrorPage } from "pages/errors/Errors";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  createButton: {
    marginLeft: 72,
  },
}));

const handleAddButtonClick = async e => {
  try {
    const isAlreadySetToken = await smartdbUtil.isAlreadySetAccessToken();
    if (!isAlreadySetToken) {
      confirmAlert(ACCESSTOKEN_ALERT_OPTIONS);
      return;
    }
  } catch (error) {
    toErrorPage(error);
    return;
  }

  history.push(
    urlUtil.setPathParams(DOCUSIGN_EDIT_PAGE_URL, {
      id: "",
    })
  );
};

const DataList = ({ list, onDelete }) => {
  const classes = useStyles();

  return (
    <>
      <Header title={t("tempaltes.docusign.list.title")} />
      <Container maxWidth="sm" className={classes.container}>
        <List list={list} onDelete={onDelete} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddButtonClick}
          className={classes.createButton}
        >
          {t("templates.list.add.button")}
        </Button>
      </Container>
    </>
  );
};

DataList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(DOCU_SIGN)).isRequired,
  onDelete: PropTypes.func,
};

export default DataList;
