import React, { useCallback } from "react";

import Avatar from "@material-ui/core/Avatar";
import EventNote from "@material-ui/icons/EventNote";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";

const BinderListOne = ({ id, title, onClick }) => {
  const _onClick = useCallback(() => onClick && onClick(id, title), [
    onClick,
    id,
    title,
  ]);

  return (
    <ListItem button onClick={_onClick}>
      <ListItemAvatar>
        <Avatar>
          <EventNote />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={title} />
    </ListItem>
  );
};

BinderListOne.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.any,
  onClick: PropTypes.func,
};

export default BinderListOne;
