import React from "react";
import Errors from "../Errors";

const errors = {
  code: "404",
  errors: [
    {
      code: "404",
      level: "ERROR",
      message: "ページが見つかりませんでした。",
    },
  ],
};

const NotFound = () => {
  return <Errors location={{ state: { errorObject: errors } }} />;
};

export default NotFound;
