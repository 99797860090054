import PropTypes from "prop-types";

export const DOCU_SIGN = {
  id: PropTypes.string.isRequired,
  docuSignTitle: PropTypes.string.isRequired,
  binderName: PropTypes.string.isRequired,
  docuSignBase: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedBy: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
};
