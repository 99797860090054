import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SmartDBLoginForm } from "components/smartdb/loginform/SmartDBLoginForm";
import Header from "elements/header/Header";
import { t } from "locales";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
}));

const SmartDBAuth = ({ onSubmit }) => {
  const classes = useStyles();

  return (
    <>
      <Header title={t("templates.auth.smartdb")} />
      <Container maxWidth="sm" className={classes.container}>
        <SmartDBLoginForm onSubmit={onSubmit} />
      </Container>
    </>
  );
};

SmartDBAuth.propTypes = {
  onSubmit: PropTypes.func,
};

export default SmartDBAuth;
