import React from "react";

import Link from "@material-ui/core/Link";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ListIcon from "@material-ui/icons/FormatListBulleted";
import GrainIcon from "@material-ui/icons/Grain";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import { history } from "@dreamarts/baas-common-front-node";
import { t } from "locales";

import {
  DOCUSIGN_LIST_PAGE_URL,
  CLOUDSIGN_LIST_PAGE_URL,
} from "constants/index";

const useStyles = makeStyles(theme => ({
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 25,
  },
  breadcrumbs: {
    marginBottom: 20,
  },
  table: {
    minWidth: 750,
  },
  dialogCustomizedWidth: {
    "max-width": "55%",
  },
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, key, type) {
  return { name, key, type };
}

const rows = [
  createData("契約書タイトル", "txt_sign_title", "文字列入力ボックス"),
  createData("契約先会社名", "txt_sign_company_name", "文字列入力ボックス"),
  createData("署名担当者名1～10", "txt_sign_name_1～10", "文字列入力ボックス"),
  createData(
    "署名担当者メールアドレス1〜10",
    "txt_sign_mail_1〜10",
    "文字列入力ボックス"
  ),
  createData("押印前の契約書", "file_sign_upload_file", "ファイルフォルダ"),
  createData("送信メッセージ", "txt_sign_message", "文章入力エリア"),
  createData("押印済の契約書", "file_sign_stamp_file", "ファイルフォルダ"),
  createData("合意締結証明書", "file_sign_cert_file", "ファイルフォルダ"),
  createData("契約締結日", "dat_sign_conclude_date", "日付選択"),
  createData(
    "押印ステータス",
    "sel_sign_status",
    "単一選択（ID：12（先方押印済）、13：（先方押印却下）は固定）"
  ),
];

const rowsCloudSign = [
  createData(
    "署名担当者所属会社名1～10",
    "txt_sign_another_company_name_1～10",
    "文字列入力ボックス"
  ),
];

const rowsDocuSign = [
  createData("編集担当者名", "txt_sign_editor_name", "文字列入力ボックス"),
  createData(
    "編集担当者メールアドレス",
    "txt_sign_editor_mail",
    "文字列入力ボックス"
  ),
];

export function SubMenuDialog(props) {
  const { listUrl } = props;

  const handleBackToList = () => {
    history.push(listUrl);
  };

  const [openItemKey, setOpenItemKey] = React.useState(false);

  const handleItemKeyOpen = () => {
    setOpenItemKey(true);
  };

  const handleClose = () => {
    setOpenItemKey(false);
  };

  const classes = useStyles();

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        <Link
          color="inherit"
          href="#"
          onClick={handleBackToList}
          className={classes.link}
        >
          <ListIcon className={classes.icon} />
          一覧
        </Link>
        <Link
          color="inherit"
          href="#"
          onClick={handleItemKeyOpen}
          className={classes.link}
        >
          <GrainIcon className={classes.icon} />
          部品キー仕様
        </Link>
      </Breadcrumbs>
      <Dialog
        open={openItemKey}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      >
        <DialogTitle id="form-dialog-title">部品キー仕様</DialogTitle>
        <DialogContent>
          <DialogContentText>
            電子契約サービスを連携する際に部品のデータを利用するが必要ため、バインダのフォーム定義で設定してください。
          </DialogContentText>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>部品名</StyledTableCell>
                  <StyledTableCell>部品キー</StyledTableCell>
                  <StyledTableCell>部品タイプ</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell>{row.key}</StyledTableCell>
                    <StyledTableCell>{row.type}</StyledTableCell>
                  </StyledTableRow>
                ))}
                {listUrl === CLOUDSIGN_LIST_PAGE_URL &&
                  rowsCloudSign.map(row => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell>{row.key}</StyledTableCell>
                      <StyledTableCell>{row.type}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                {listUrl === DOCUSIGN_LIST_PAGE_URL &&
                  rowsDocuSign.map(row => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell>{row.key}</StyledTableCell>
                      <StyledTableCell>{row.type}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

SubMenuDialog.propTypes = {
  listUrl: PropTypes.string,
};
