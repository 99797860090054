import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "elements/header/Header";
import AdobeSignForm from "components/form/AdobeSignForm";
import { t } from "locales";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
}));

const EditAdobeSign = ({ initialState, onSubmit }) => {
  const classes = useStyles();

  return (
    <>
      <Header title={t("templates.adobesign.edit.title")} />
      <Container maxWidth="sm" className={classes.container}>
        <AdobeSignForm initialState={initialState} onSubmit={onSubmit} />
      </Container>
    </>
  );
};

EditAdobeSign.propTypes = {
  initialState: PropTypes.shape({
    title: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
};

export default EditAdobeSign;
