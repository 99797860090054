export const ERROR_PAGE_URL = "/errors";
export const AUTH_TOP_PAGE_URL = "/auth";

export const SMARTDB_AUTH_PAGE_URL = "/auth/smartdb";
export const SMARTDB_ACCESS_TOKEN_PAGE_URL = "/settings/smartdb/accessToken";

export const TOP_PAGE_URL = "/";
export const DOCUSIGN_LIST_PAGE_URL = "/list/docusign";
export const CLOUDSIGN_LIST_PAGE_URL = "/list/cloudsign";
export const ADOBESIGN_LIST_PAGE_URL = "/list/adobesign";

export const CLOUDSIGN_EDIT_PAGE_URL = "/cloudSign/:id?";
export const DOCUSIGN_EDIT_PAGE_URL = "/docuSign/:id?";
export const ADOBESIGN_EDIT_PAGE_URL = "/adobeSign/:id?";

export const CLOUDSIGN_LIST_API_URL = "/api/cloudSign";
export const DOCUSIGN_LIST_API_URL = "/api/docuSign";
export const ADOBESIGN_LIST_API_URL = "/api/adobeSign";

export const CLOUDSIGN_API_URL = "/api/cloudSign/:id";
export const DOCUSIGN_API_URL = "/api/docuSign/:id";
export const ADOBESIGN_API_URL = "/api/adobeSign/:id";

export const DOCUSIGN_JWT_CONSENT_API_URL =
  "/api/docuSign/jwtConsent/:binderId";

export const IP_ADDRESS_API_URL = "/api/possibleOutboundIpAddresses";

export const SDB_BRD_LIST = "/api/proxy/user/hibiki/rest/2/binders";
export const SDB_BRD_DETAIL = "/api/proxy/user/hibiki/rest/2/binders/:id";
export const SDB_CHOICES_LIST =
  "/api/proxy/user/hibiki/rest/2/binders/:binderId/form/items/:itemId";

export const LOGOUT_PAGE_URL = "/logout";
