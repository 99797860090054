import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SmartDBAccessTokenForm } from "components/smartdb/accesstokenform/SmartDBAccessTokenForm";
import Header from "elements/header/Header";
import { t } from "locales";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
}));

const SmartDBAccessToken = ({ initialState, onSubmit }) => {
  const classes = useStyles();

  return (
    <>
      <Header title={t("templates.settings.smartdb.accesstoken.title")} />
      <Container maxWidth="sm" className={classes.container}>
        <SmartDBAccessTokenForm
          initialState={initialState}
          onSubmit={onSubmit}
        />
      </Container>
    </>
  );
};

SmartDBAccessToken.propTypes = {
  initialState: PropTypes.shape({
    accessToken: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
};

export default SmartDBAccessToken;
