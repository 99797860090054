import React, { useCallback } from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import Avatar from "@material-ui/core/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStamp } from "@fortawesome/free-solid-svg-icons";

import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  inline: {
    display: "inline",
  },
}));

/**
 * Listの一行を表すcomponent
 * @param {object} props
 * @param {string} id
 * @param {string} props.title
 * @param {string} props.signBase
 * @param {string} props.binderName
 * @param {function} props.onClick
 * @param {function} props.onDelete
 */
const DataListItem = ({
  id,
  title,
  binderName,
  signBase,
  onClick,
  onDelete,
}) => {
  const _onClick = useCallback(() => onClick && onClick(id), [onClick, id]);
  const _onDelete = useCallback(() => onDelete && onDelete(id), [onDelete, id]);

  const classes = useStyles();

  return (
    <ListItem button onClick={_onClick}>
      <ListItemIcon>
        <Avatar>
          <FontAwesomeIcon icon={faStamp} />
        </Avatar>
      </ListItemIcon>
      {signBase && (
        <ListItemText
          primary={title}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {signBase}
              </Typography>
              &nbsp; - {binderName}
            </React.Fragment>
          }
        />
      )}
      {!signBase && (
        <ListItemText
          primary={title}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              />
              {binderName}
            </React.Fragment>
          }
        />
      )}
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="Delete" onClick={_onDelete}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

DataListItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  binderName: PropTypes.string.isRequired,
  signBase: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
};

export default DataListItem;
