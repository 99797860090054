import "./design/globalStyle.scss";

import Applications from "applications";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Pages from "./pages";
import React from "react";
import materialTheme from "design/materialTheme";

const App = () => (
  <MuiThemeProvider theme={materialTheme}>
    <Applications>
      <Pages />
    </Applications>
  </MuiThemeProvider>
);

export default App;
