import PropTypes from "prop-types";
import React from "react";
import { t } from "locales";
import { toErrorPage } from "pages/errors/Errors";

class Errors extends React.Component {
  toErrorPage = toErrorPage;

  componentDidCatch(error, info) {
    const errorObject = {
      code: "C_500",
      errors: [
        {
          code: "C_500",
          level: "ERROR",
          message: t("application.errors.message.500"),
        },
      ],
    };

    this.toErrorPage(errorObject);
  }

  render() {
    return this.props.children;
  }
}

Errors.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Errors;
