import {
  history,
  urlUtil,
  smartdbUtil,
} from "@dreamarts/baas-common-front-node";

import { confirmAlert } from "react-confirm-alert";

import List from "@material-ui/core/List";
import PropTypes from "prop-types";
import React from "react";
import { ADOBE_SIGN } from "constants/proptypes/adobeSign";
import { ADOBESIGN_EDIT_PAGE_URL } from "constants/index";
import ListItem from "components/list/ListItem";
import { toErrorPage } from "pages/errors/Errors";
import { t } from "locales";

import ACCESSTOKEN_ALERT_OPTIONS from "constants/option/accessToken";

const _onItemClick = async id => {
  try {
    const isAlreadySetToken = await smartdbUtil.isAlreadySetAccessToken();
    if (!isAlreadySetToken) {
      confirmAlert(ACCESSTOKEN_ALERT_OPTIONS);
      return;
    }
  } catch (error) {
    toErrorPage(error);
    return;
  }

  history.push(urlUtil.setPathParams(ADOBESIGN_EDIT_PAGE_URL, { id }));
};

/**
 *
 * @param {object} props
 * @param {[{id:string, title:string, createdBy:string, createdAt:string, updatedBy:string, updatedAt:string}]} props.todoList
 */
const DataList = ({ list, onDelete }) => {
  if (list.length === 0) {
    return t("compositions.list.noitem.message");
  }

  return (
    <List>
      {list.map((entry, index) => (
        <ListItem
          title={entry.adobeSignTitle}
          binderName={entry.binderName}
          id={entry.id}
          key={index}
          onClick={_onItemClick}
          onDelete={onDelete}
        />
      ))}
    </List>
  );
};

DataList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(ADOBE_SIGN)),
  onDelete: PropTypes.func,
};

export default DataList;
