import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Container from "@material-ui/core/Container";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Header from "elements/header/Header";
import { t } from "locales";

const useStyles = makeStyles(theme => ({
  item: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    textAlign: "center",
  },
}));

const Logout = () => {
  const classes = useStyles();

  return (
    <div>
      <Header title={t("templates.logout.title")} />
      <Container maxWidth="md" className={classes.container}>
        <CheckCircleOutlineIcon color="primary" fontSize="large" />
        <Typography variant="inherit" className={classes.item}>
          {t("templates.logout.complete")}
        </Typography>
      </Container>
    </div>
  );
};

export default Logout;
