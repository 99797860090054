import { smartdbUtil } from "@dreamarts/baas-common-front-node";
import React from "react";
import { useAsync } from "react-use";
import Template from "templates/settings/smartdb/accesstoken/SmartDBAccessToken";
import Loading from "elements/loading/Loading";
import { toErrorPage } from "pages/errors/Errors";

const getSmartDBAccessToken = async () => {
  try {
    return await smartdbUtil.getAccessToken();
  } catch (error) {
    toErrorPage(error);
  }
};

const setSmartDBAccessToken = async accessToken => {
  try {
    await smartdbUtil.setAccessToken(accessToken);
  } catch (error) {
    toErrorPage(error);
  }
};

export const SmartDBAccessToken = () => {
  const accessToken = useAsync(getSmartDBAccessToken);

  if (accessToken.loading || !accessToken.value) {
    return <Loading />;
  }

  return (
    <Template
      initialState={accessToken.value}
      onSubmit={setSmartDBAccessToken}
    />
  );
};
