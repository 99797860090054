import { List } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { smartdbUtil, fetchUtil } from "@dreamarts/baas-common-front-node";
import { useAsync } from "react-use";
import BinderListOne from "./BinderListOne";
import { SDB_BRD_LIST } from "constants/index";
import { t } from "locales";
import { toErrorPage } from "pages/errors/Errors";

const { get } = fetchUtil;

const getBinderListAsync = async keyword => {
  // keywordが未入力の場合はバインダのリストを取得しない
  if (!keyword) {
    return;
  }

  // TODO: 取得する最大数があるのでページングが必要
  const body = await get(SDB_BRD_LIST, { keyword: keyword, limit: 75 });

  if (body.binders === undefined) {
    toErrorPage(body);
  } else {
    return smartdbUtil.adjustResponseToArray(body.binders.binder);
  }
};

export const BinderList = props => {
  const { handleBinderSelect, keyword } = props;
  const binderList = useAsync(() => getBinderListAsync(keyword), [keyword]);

  const onClick = (id, title) => {
    handleBinderSelect(id, title);
  };

  if (binderList.loading) {
    // TODO: サイズを調整したローディングコンポーネントを使う
    return <div>{t("elements.loading.message")}</div>;
  }

  if (!binderList.value) {
    return null;
  }

  return (
    <List>
      {binderList.value.map((binder, index) => (
        <BinderListOne
          title={binder.name}
          id={binder.id}
          key={index}
          onClick={onClick}
        />
      ))}
    </List>
  );
};

BinderList.propTypes = {
  handleBinderSelect: PropTypes.func,
  keyword: PropTypes.string,
};
