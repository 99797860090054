import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { authUtil } from "@dreamarts/baas-common-front-node";
import { makeStyles } from "@material-ui/core/styles";
import { useAsync } from "react-use";
import { logout } from "pages/logout/Logout";
import { t } from "locales";
import { toTopPage } from "pages";
import { SMARTDB_ACCESS_TOKEN_PAGE_URL } from "constants/index";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  avatar: {
    margin: 5,
    background: "white",
  },
  iconLink: {
    textDecoration: "none",
    color: "white",
  },
  divider: {
    margin: 5,
  },
  accountMenu: {
    minWidth: 300,
  },
}));

const Header = ({ title }) => {
  const [accountOpen, setAccountOpen] = React.useState(false);
  const accountButtonRef = React.useRef(null);
  const classes = useStyles();

  const isBaasAdmin = useAsync(authUtil.isBaasAdmin);
  const loginUser = useAsync(authUtil.getUser);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Menu"
            onClick={toTopPage}
          >
            <HomeIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {isBaasAdmin.value && (
            <Link
              to={SMARTDB_ACCESS_TOKEN_PAGE_URL}
              className={classes.iconLink}
            >
              <Tooltip
                title={t("templates.settings.smartdb.accesstoken.title")}
                aria-label="access-token-settings"
              >
                <IconButton color="inherit" aria-label="settings">
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </Link>
          )}
          {loginUser.value && (
            <div>
              <IconButton
                size="small"
                aria-controls="account-menu"
                aria-haspopup="true"
                aria-expanded="true"
                onClick={() => setAccountOpen(true)}
                ref={accountButtonRef}
              >
                <Avatar
                  alt={loginUser.value.userName}
                  src={loginUser.value.photoUrl}
                  className={classes.avatar}
                />
              </IconButton>
              <Menu
                open={accountOpen}
                onClose={() => setAccountOpen(false)}
                anchorEl={accountButtonRef.current}
                PaperProps={{
                  style: {
                    minWidth: 220,
                  },
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      alt={loginUser.value.userName}
                      src={loginUser.value.photoUrl}
                      className={classes.avatar}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={loginUser.value.userName} />
                </ListItem>
                <Divider className={classes.divider} />
                <MenuItem onClick={logout}>
                  <ListItemIcon>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">
                    {t("templates.logout.title")}
                  </Typography>
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.shape({
    photoUrl: PropTypes.string,
    userName: PropTypes.string,
  }),
};

export default Header;
