import React from "react";
import { authUtil } from "@dreamarts/baas-common-front-node";
import { history } from "@dreamarts/baas-common-front-node";
import { useAsync } from "react-use";
import { LOGOUT_PAGE_URL } from "constants/index";
import Loading from "elements/loading/Loading";
import Template from "templates/logout/Logout";
import { toErrorPage } from "pages/errors/Errors";

export const logout = () => {
  try {
    history.push(LOGOUT_PAGE_URL);
  } catch (e) {
    toErrorPage(e);
  }
};

const Logout = () => {
  const logout = useAsync(authUtil.logout);

  if (logout.loading) {
    return <Loading />;
  }

  return <Template />;
};

export default Logout;
