import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  row: {
    height: "100vh",
  },
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.row}
    >
      <Grid item xs={1}>
        <CircularProgress className={classes.progress} />
      </Grid>
    </Grid>
  );
};

export default Loading;
