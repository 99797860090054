import React, { useCallback } from "react";

import Avatar from "@material-ui/core/Avatar";
import EventNote from "@material-ui/icons/EventNote";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  itemId: {
    flex: "0 1 auto",
  },
  itemName: {
    marginLeft: 20,
  },
}));

const RecordItemListOne = ({ sdbRecordItemId, sdbRecordItemName, onClick }) => {
  const _onClick = useCallback(() => onClick && onClick(sdbRecordItemId), [
    onClick,
    sdbRecordItemId,
  ]);

  const classes = useStyles();

  return (
    <ListItem button onClick={_onClick}>
      <ListItemAvatar>
        <Avatar>
          <EventNote />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={sdbRecordItemId} className={classes.itemId} />
      <ListItemText primary={sdbRecordItemName} className={classes.itemName} />
    </ListItem>
  );
};

RecordItemListOne.propTypes = {
  sdbRecordItemId: PropTypes.number.isRequired,
  sdbRecordItemName: PropTypes.string,
  onClick: PropTypes.func,
};

export default RecordItemListOne;
