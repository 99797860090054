import { IntlProvider, addLocaleData } from "react-intl";

import ja from "react-intl/locale-data/ja";
import jaTranslationJson from "./ja/translation";

addLocaleData([...ja]);

const { intl } = new IntlProvider({
  locale: "ja",
  messages: jaTranslationJson,
}).getChildContext();

/**
 * 文字列を国際化します
 * @param {string} text ex. elements.logo.meijin
 * @param {object[]} values 変数埋め込み用の値
 * @returns {string} 国際化後の文字列 ex. 名人
 */
export const t = (text, values) => {
  return values
    ? intl.formatMessage({ id: text }, ...values)
    : intl.formatMessage({ id: text });
};
