import React from "react";
import PropTypes from "prop-types";
import {
  fetchUtil,
  smartdbUtil,
  urlUtil,
} from "@dreamarts/baas-common-front-node";
import { useAsync } from "react-use";
import { List } from "@material-ui/core";
import RecordItemListOne from "../../components/smartdb/dialog/RecordItemListOne";
import { SDB_CHOICES_LIST } from "constants/index";
import { toErrorPage } from "pages/errors/Errors";
import { t } from "locales";

const { get } = fetchUtil;

const getBinderDetailAsync = async (binderId, itemId) => {
  if (!binderId || !itemId) {
    return;
  }

  const body = await get(
    urlUtil.setPathParams(SDB_CHOICES_LIST, {
      binderId: binderId,
      itemId: itemId,
    })
  );

  if (!body.item) {
    toErrorPage(body);
  } else {
    return smartdbUtil.adjustResponseToArray(body.item.choice);
  }
};

export const RecordItemChoiceList = props => {
  const { handleItemSelect, binderId, itemId } = props;
  const binderItemChoice = useAsync(
    () => getBinderDetailAsync(binderId, itemId),
    [binderId, itemId]
  );

  if (binderItemChoice.loading) {
    // TODO: サイズ調整したローディングコンポーネントを使う
    return <div>{t("elements.loading.message")}</div>;
  }

  if (!binderItemChoice.value) {
    return null;
  }

  return (
    <List>
      {binderItemChoice.value.map((choice, index) => {
        return (
          <RecordItemListOne
            sdbRecordItemName={choice.name}
            sdbRecordItemId={choice.id}
            key={index}
            onClick={handleItemSelect}
          />
        );
      })}
    </List>
  );
};

RecordItemChoiceList.propTypes = {
  handleItemSelect: PropTypes.func,
  binderId: PropTypes.number,
  itemId: PropTypes.number,
};
