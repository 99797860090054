import React, { useEffect, useState } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { authUtil, history } from "@dreamarts/baas-common-front-node";
import { SnackbarProvider } from "@dreamarts/da-react-ui";
import Auth, { toAuthTopPage } from "./auth/Auth";
import Errors, { toAppForbiddenPage } from "./errors/Errors";

import AdobeSignEdit from "./edit/AdobeSign";
import AdobeSignList from "./list/AdobeSign";
import CloudSignEdit from "./edit/CloudSign";
import CloudSignList from "./list/CloudSign";
import DocuSignEdit from "./edit/DocuSign";
import DocuSignList from "./list/DocuSign";
import Logout from "./logout/Logout";
import NotFound from "./errors/notfound/NotFound";
import { SmartDBAccessToken } from "./settings/smartdb/accesstoken/SmartDBAccessToken";
import SmartDBAuth from "./auth/smartdb/SmartDBAuth";
import Top from "./top/Top";
import Loading from "elements/loading/Loading";
import {
  ADOBESIGN_EDIT_PAGE_URL,
  ADOBESIGN_LIST_PAGE_URL,
  AUTH_TOP_PAGE_URL,
  CLOUDSIGN_EDIT_PAGE_URL,
  CLOUDSIGN_LIST_PAGE_URL,
  DOCUSIGN_EDIT_PAGE_URL,
  DOCUSIGN_LIST_PAGE_URL,
  ERROR_PAGE_URL,
  LOGOUT_PAGE_URL,
  SMARTDB_ACCESS_TOKEN_PAGE_URL,
  SMARTDB_AUTH_PAGE_URL,
  TOP_PAGE_URL,
} from "constants/index";

export const toTopPage = async () => {
  // エラー画面からトップページに遷移するパターンがあるため、ログイン判定を実施
  if (!authUtil.isLoggedIn()) {
    toAuthTopPage();
    return;
  }

  const isBaasAdmin = await authUtil.isBaasAdmin();
  if (!isBaasAdmin) {
    toAppForbiddenPage();
    return;
  }

  history.push(TOP_PAGE_URL);
};

const Routes = () => {
  const [isBaasAdmin, setIsBaasAdmin] = useState(null);

  const page = (
    <SnackbarProvider>
      <Router history={history}>
        <Switch>
          <Route path={ERROR_PAGE_URL} component={Errors} exact />
          <Route path={AUTH_TOP_PAGE_URL} component={Auth} exact />
          <Route path={SMARTDB_AUTH_PAGE_URL} component={SmartDBAuth} exact />
          <Route
            path={SMARTDB_ACCESS_TOKEN_PAGE_URL}
            component={SmartDBAccessToken}
            exact
          />

          <Route path={TOP_PAGE_URL} component={Top} exact />

          <Route path={DOCUSIGN_LIST_PAGE_URL} component={DocuSignList} exact />
          <Route
            path={CLOUDSIGN_LIST_PAGE_URL}
            component={CloudSignList}
            exact
          />
          <Route
            path={ADOBESIGN_LIST_PAGE_URL}
            component={AdobeSignList}
            exact
          />

          <Route
            path={CLOUDSIGN_EDIT_PAGE_URL}
            component={CloudSignEdit}
            exact
          />
          <Route path={DOCUSIGN_EDIT_PAGE_URL} component={DocuSignEdit} exact />
          <Route
            path={ADOBESIGN_EDIT_PAGE_URL}
            component={AdobeSignEdit}
            exact
          />
          <Route path={LOGOUT_PAGE_URL} component={Logout} exact />

          <Route component={NotFound} />
        </Switch>
      </Router>
    </SnackbarProvider>
  );

  useEffect(() => {
    authUtil.isBaasAdmin().then(setIsBaasAdmin);
  }, []);

  // ログアウトページであればそのまま通す
  if (history.location.pathname.includes(LOGOUT_PAGE_URL)) {
    return page;
  }

  // ログインしていなければ認証画面へ
  if (!authUtil.isLoggedIn()) {
    toAuthTopPage();
    return page;
  }

  // isBaasAdminの結果待ちの場合Loadingを表示
  if (typeof isBaasAdmin !== "boolean") {
    return <Loading />;
  }

  // isBaasAdminが無効の場合Forbiddenページへ
  if (!isBaasAdmin) {
    toAppForbiddenPage();
  }

  return page;
};

export default Routes;
