import { authUtil, history } from "@dreamarts/baas-common-front-node";
import React from "react";
import { SMARTDB_AUTH_PAGE_URL } from "constants/index";
import Template from "templates/auth/smartdb/SmartDBAuth";
import { toTopPage } from "pages";
import { toErrorPage } from "pages/errors/Errors";

export const toSmartDBAuthPage = () => {
  history.push(SMARTDB_AUTH_PAGE_URL);
};

const handleLogin = async (loginid, password) => {
  try {
    await authUtil.authSmartDB(loginid, password);
    toTopPage();
  } catch (error) {
    toErrorPage(error);
  }
};

const SmartDBAuth = () => {
  return <Template onSubmit={handleLogin} />;
};

export default SmartDBAuth;
