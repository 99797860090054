import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "elements/header/Header";
import CloudSignForm from "components/form/CloudSignForm";
import { t } from "locales";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
}));

const Edit = ({ initialState, onSubmit }) => {
  const classes = useStyles();

  return (
    <>
      <Header title={t("templates.cloudsign.edit.title")} />
      <Container maxWidth="sm" className={classes.container}>
        <CloudSignForm initialState={initialState} onSubmit={onSubmit} />
      </Container>
    </>
  );
};

Edit.propTypes = {
  initialState: PropTypes.shape({
    title: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
};

export default Edit;
