import {
  fetchUtil,
  smartdbUtil,
  urlUtil,
} from "@dreamarts/baas-common-front-node";
import { SDB_BRD_DETAIL } from "constants/index";

const { get } = fetchUtil;

const getItemMapping = async binderId => {
  if (!binderId) {
    return;
  }

  const body = await get(
    urlUtil.setPathParams(SDB_BRD_DETAIL, { id: binderId })
  );

  if (!body.binder) {
    // toErrorPage(body);
    return;
  }

  const sdbItem = smartdbUtil.adjustResponseToArray(body.binder.item);

  // {sdbItemKey, sdbItemId}のmapを作成する
  const sdbItemMap = new Map();
  for (const item of sdbItem) {
    if (!item.key) {
      continue;
    }
    sdbItemMap.set(item.key, item.id);
  }

  return sdbItemMap;
};

const binderComponentKey = {
  txtSignTitle: "txt_sign_title",
  txtSignCompanyName: "txt_sign_company_name",
  txtSignEditorName: "txt_sign_editor_name",
  txtSignEditorMail: "txt_sign_editor_mail",
  txtSignMessage: "txt_sign_message",
  fileSignUploadFile: "file_sign_upload_file",
  fileSignStampFile: "file_sign_stamp_file",
  fileSignCertFile: "file_sign_cert_file",
  datSignConcludeDate: "dat_sign_conclude_date",
  selSignStatus: "sel_sign_status",
};

const MAX_PARTNER = 10;
const binderComponentKeyPartners = [];
for (let i = 0; i < MAX_PARTNER; i++) {
  binderComponentKeyPartners.push({
    txtSignName: `txt_sign_name_${i + 1}`,
    txtSignMail: `txt_sign_mail_${i + 1}`,
    txtSignAnotherCompanyName: `txt_sign_another_company_name_${i + 1}`,
  });
}

export const setInitialMap = async (binderId, getState, setState) => {
  const sdbItemMap = await getItemMapping(binderId);

  if (!sdbItemMap) {
    return;
  }

  // 部品キーと部品IDのマッピング初期値を設定する
  const initialMap = {};
  Object.entries(binderComponentKey).forEach(([key, value]) => {
    if (sdbItemMap.get(value)) {
      initialMap[key] = sdbItemMap.get(value);
      return;
    }
    initialMap[key] = "";
  });
  const txtSignPartners = binderComponentKeyPartners.map((value, index) => {
    let name = "";
    let mail = "";
    let companyName = "";
    if (sdbItemMap.get(value.txtSignName)) {
      name = sdbItemMap.get(value.txtSignName);
    }
    if (sdbItemMap.get(value.txtSignMail)) {
      mail = sdbItemMap.get(value.txtSignMail);
    }
    if (sdbItemMap.get(value.txtSignAnotherCompanyName)) {
      companyName = sdbItemMap.get(value.txtSignAnotherCompanyName);
    }
    return {
      txtSignName: name,
      txtSignMail: mail,
      txtSignAnotherCompanyName: companyName,
    };
  });
  initialMap.txtSignPartners = txtSignPartners;
  setState(initialMap);
};
