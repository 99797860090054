import PropTypes from "prop-types";

export const CLOUD_SIGN = {
  id: PropTypes.string.isRequired,
  cloudSignTitle: PropTypes.string.isRequired,
  binderName: PropTypes.string.isRequired,
  cloudSignBase: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedBy: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
};
