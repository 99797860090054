import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";
import { BinderList } from "components/form/BinderList.js";
import { t } from "locales";

const useStyles = makeStyles(theme => ({
  keywordTextField: {
    flexGrow: 1,
  },
  searchButton: {
    marginLeft: 16,
  },
}));

export function BinderListDialogs(props) {
  const { setData } = props;
  const { open, handleClose } = props;
  const [textFieldValue, setTextFieldValue] = useState("");
  const [keyword, setKeyword] = useState("");
  const classes = useStyles();
  const { binderIdField = "binderId", binderNameField = "binderName" } = props;

  const handleStartSearch = useCallback(() => setKeyword(textFieldValue), [
    textFieldValue,
  ]);

  const handleChangeTextField = useCallback(
    e => setTextFieldValue(e.target.value),
    []
  );

  const handleKeyPressTextField = useCallback(
    e => {
      if (e.key === "Enter") {
        handleStartSearch();
      }
    },
    [handleStartSearch]
  );

  const handleBinderSelect = useCallback(
    (id, name) => {
      handleClose();
      setData(binderIdField, id);
      setData(binderNameField, name);
    },
    [binderIdField, binderNameField, handleClose, setData]
  );

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          連携先バインダを選んでください
        </DialogTitle>
        <DialogContent dividers>
          <Grid container alignItems="center">
            <TextField
              label="キーワード"
              value={textFieldValue}
              onKeyPress={handleKeyPressTextField}
              onChange={handleChangeTextField}
              className={classes.keywordTextField}
              required
            />
            <Button
              variant="contained"
              onClick={handleStartSearch}
              className={classes.searchButton}
            >
              {t("common.search")}
            </Button>
          </Grid>
          <BinderList
            handleBinderSelect={handleBinderSelect}
            keyword={keyword}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

BinderListDialogs.propTypes = {
  setData: PropTypes.func,
  open: PropTypes.bool,
  handleClickOpen: PropTypes.func,
  handleClose: PropTypes.func,
  binderIdField: PropTypes.string,
  binderNameField: PropTypes.string,
};
