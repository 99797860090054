import Errors from "./errors/Errors";
import PropTypes from "prop-types";
import React from "react";

const Applications = props => <Errors>{props.children}</Errors>;

Applications.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Applications;
