import { fetchUtil, history, urlUtil } from "@dreamarts/baas-common-front-node";
import PropTypes from "prop-types";
import React from "react";
import { useAsync } from "react-use";
import {
  DOCUSIGN_API_URL,
  DOCUSIGN_LIST_API_URL,
  DOCUSIGN_LIST_PAGE_URL,
  IP_ADDRESS_API_URL,
} from "constants/index";

import Loading from "elements/loading/Loading";
import Template from "templates/edit/DocuSign";
import { toErrorPage } from "pages/errors/Errors";

const { get, post } = fetchUtil;

const MAX_PARTNER = 10;

const getDataAsync = async id => {
  if (!id) {
    // 署名担当者の初期化
    const txtSignPartners = [];
    for (let i = 0; i < MAX_PARTNER; i++) {
      txtSignPartners.push({ txtSignName: "", txtSignMail: "" });
    }
    return { txtSignPartners: txtSignPartners }; //return default state.
  }

  try {
    const body = await get(urlUtil.setPathParams(DOCUSIGN_API_URL, { id }));
    const ipAddress = await get(IP_ADDRESS_API_URL);
    body.data.ipAddress = ipAddress.data;
    return fetchUtil.pickResponseData(body);
  } catch (error) {
    toErrorPage(error);
  }
};

const postDataAsync = async state => {
  try {
    const body = await post(DOCUSIGN_LIST_API_URL, state);
    fetchUtil.pickResponseData(body);
    history.push(DOCUSIGN_LIST_PAGE_URL);
  } catch (error) {
    toErrorPage(error);
  }
};

const EditDocuSign = ({ match }) => {
  const id =
    match && match.params && match.params.id ? match.params.id : undefined;

  const docuSign = useAsync(async () => getDataAsync(id), [getDataAsync]);

  if (docuSign.loading || !docuSign.value) {
    return <Loading />;
  }

  return <Template initialState={docuSign.value} onSubmit={postDataAsync} />;
};

EditDocuSign.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, () => undefined]),
    }),
  }),
};

export default EditDocuSign;
