import { authUtil, history } from "@dreamarts/baas-common-front-node";
import React, { useCallback } from "react";
import { useAsync } from "react-use";
import { toSmartDBAuthPage } from "./smartdb/SmartDBAuth";
import { AUTH_TOP_PAGE_URL } from "constants/index";
import { genErrorObject, toErrorPage } from "pages/errors/Errors";

import Loading from "elements/loading/Loading";
import Template from "templates/auth/Auth";
import { t } from "locales";
import { toTopPage } from "pages";

export const toAuthTopPage = () => {
  history.push(AUTH_TOP_PAGE_URL);
};

/** redirectログインから返ってきた際に自動で再認証を走らせるためのフラグを置く場所のキー */
export const IS_STARTED_AZUREAD_LOGIN = "isStartedAzureADLogin";

const handleAzureADAuth = async () => {
  try {
    const complete = await authUtil.authAzureAD();

    // popupログイン完了でトップページに移動
    if (complete) {
      toTopPage();
      return;
    }

    // redirectログインを開始する場合のルート
    localStorage.setItem(IS_STARTED_AZUREAD_LOGIN, "true");
  } catch (e) {
    toErrorPage(e);
  }
};

const getAuthSettings = async () => {
  let authSettings;
  try {
    authSettings = await authUtil.getAuthSettings();
  } catch (error) {
    toErrorPage(error);
    return;
  }

  const availableSettings = authSettings.filter(settings => {
    return settings.enabled;
  });

  if (0 === availableSettings.length) {
    toErrorPage(genErrorObject(t("templates.auth.unavailable")));
    return;
  }

  // 利用可能な認証方式が1つだけの場合はその認証を開始する
  // 認証方式が増えた場合は適宜判定の追加が必要
  // 判定に不足がある場合はエラーとする
  if (1 === availableSettings.length) {
    switch (availableSettings[0].method) {
      case "local":
        toSmartDBAuthPage();
        return;
      case "oidc":
        switch (availableSettings[0].preset) {
          case "AzureAD":
            await handleAzureADAuth();
            return;
          default:
            toErrorPage(genErrorObject(t("templates.auth.unknown.authtype")));
            return;
        }
      case "saml":
        window.location.href = availableSettings[0].samlEndPoint;
        return;
      default:
        toErrorPage(genErrorObject(t("templates.auth.unknown.authtype")));
        return;
    }
  }

  return authSettings;
};

const Auth = () => {
  const authSettings = useAsync(getAuthSettings);

  const handleSamlAuth = useCallback(
    () => {
      window.location.href = authSettings.value.find(authSetting => {
        return authSetting.method === "saml";
      }).samlEndPoint;
    },
    [authSettings.value]
  );

  if (authSettings.loading || !authSettings.value) {
    return <Loading />;
  }

  return (
    <Template
      handleSmartDBAuth={toSmartDBAuthPage}
      handleAzureADAuth={handleAzureADAuth}
      handleSamlAuth={handleSamlAuth}
      authSettings={authSettings.value}
    />
  );
};

export default Auth;
