import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useCallback, useRef } from "react";

import { useSnackbar } from "@dreamarts/da-react-ui";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import { useGetSetState } from "react-use";
import { BinderListDialogs } from "./BinderListDialog";
import { SubMenuDialog } from "./SubMenuDialog";
import { BinderItemMappingForm } from "./BinderItemMappingForm";
import { t } from "locales";

import { ADOBESIGN_LIST_PAGE_URL } from "constants/index";

const useStyles = makeStyles(theme => ({
  binderIdText: {
    flexGrow: 1,
  },
  binderSelectButton: {
    marginTop: 8,
    marginLeft: 16,
  },
  copyBinderSelectButton: {
    marginBottom: 22,
  },
  submitButton: {
    marginTop: 16,
  },
}));

const AdobeSignForm = ({ initialState, onSubmit }) => {
  const formRef = useRef();

  const [getState, setState] = useGetSetState(initialState);
  const classes = useStyles();

  const { enqueueSuccessSnackbar } = useSnackbar();

  const handleChange = useCallback(
    name => event => {
      setState({ [name]: event.target.value });
    },
    [setState]
  );

  const handleSelect = useCallback(
    (name, id) => {
      setState({ [name]: id });
    },
    [setState]
  );

  const _onSubmit = useCallback(
    () => {
      if (formRef.current.checkValidity()) {
        onSubmit && onSubmit(getState());
        enqueueSuccessSnackbar("AdobeSign定義を保存しました。");
      } else {
        formRef.current.reportValidity();
      }
    },
    [getState, onSubmit, enqueueSuccessSnackbar]
  );

  // for バインダリスト
  const [binderIdField, setBinderIdField] = React.useState("binderId");
  const [binderNameField, setBinderNameField] = React.useState("binderName");
  const [openBrdList, setOpenBrdList] = React.useState(false);
  const handleBinderListOpen = useCallback(() => {
    setBinderIdField("binderId");
    setBinderNameField("binderName");
    setOpenBrdList(true);
  }, []);
  const handleBinderListClose = useCallback(id => {
    setOpenBrdList(false);
  }, []);
  const handleCopyBinderListOpen = useCallback(() => {
    setBinderIdField("copyBinderId");
    setBinderNameField("copyBinderName");
    setOpenBrdList(true);
  }, []);

  const webhookUrl = "https://" + window.location.host + "/api/adobeSign/send";
  const incomingUrl =
    "https://" + window.location.host + "/api/adobeSign/incoming";

  return (
    <form ref={formRef}>
      <SubMenuDialog listUrl={ADOBESIGN_LIST_PAGE_URL} />
      <Grid container direction="column">
        <TextField
          label="タイトル"
          value={getState().adobeSignTitle}
          onChange={handleChange("adobeSignTitle")}
          margin="normal"
          required
        />
        <TextField
          label="AdobeSignのログインID(メールアドレス)"
          value={getState().adobeSignLoginId}
          onChange={handleChange("adobeSignLoginId")}
          margin="normal"
        />
        {!getState().id && (
          <TextField
            label="アクセストークン(インテグレーションキー)"
            value={getState().adobeSignIntegrationKey}
            onChange={handleChange("adobeSignIntegrationKey")}
            margin="normal"
            required
          />
        )}
        {getState().id && (
          <TextField
            label="アクセストークン(インテグレーションキー)"
            value="************************"
            margin="normal"
            disabled
            helperText="アクセストークンは編集できません。"
          />
        )}
        <Grid container alignItems="center">
          <TextField
            label="連携バインダID"
            value={getState().binderId || ""}
            onChange={handleChange("binderId")}
            margin="normal"
            className={classes.binderIdText}
            required
          />
          <Button
            variant="contained"
            onClick={handleBinderListOpen}
            className={classes.binderSelectButton}
          >
            バインダ選択
          </Button>
        </Grid>
        <TextField
          label="連携バインダ名"
          value={getState().binderName || ""}
          onChange={handleChange("binderName")}
          margin="normal"
        />
        <Grid container alignItems="center">
          <TextField
            label="コピー先連携バインダID"
            value={getState().copyBinderId || ""}
            onChange={handleChange("copyBinderId")}
            margin="normal"
            className={classes.binderIdText}
            helperText="確定した契約書をコピーしたいバインダを指定してください。"
          />
          <Button
            variant="contained"
            onClick={handleCopyBinderListOpen}
            className={`${classes.binderSelectButton} ${
              classes.copyBinderSelectButton
            }`}
          >
            バインダ選択
          </Button>
        </Grid>
        <TextField
          label="コピー先連携バインダ名"
          value={getState().copyBinderName || ""}
          onChange={handleChange("copyBinderName")}
          margin="normal"
        />
        <BinderItemMappingForm
          esignType="AdobeSign"
          binderId={getState().binderId}
          getState={getState}
          setState={setState}
        />
        {getState().id && (
          <>
            <TextField
              label="WebhookURL"
              value={webhookUrl}
              margin="normal"
              disabled
              helperText="SmartDBプロセスのWebhookURLに設定してください。"
            />
            <TextField
              label="AdobeSignの連携WebAPI"
              value={incomingUrl}
              margin="normal"
              disabled
              helperText="AdobeSignの連携WebAPIに設定してください。"
            />
            <TextField
              label="IPアドレス"
              value={getState().ipAddress}
              margin="normal"
              disabled
              helperText="IPアドレスを制御する場合、設定してください。"
            />
          </>
        )}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={_onSubmit}
        className={classes.submitButton}
      >
        {t("common.save")}
      </Button>
      {/* ダイアログ */}
      <BinderListDialogs
        setData={handleSelect}
        open={openBrdList}
        handleClickOpen={handleBinderListOpen}
        handleClose={handleBinderListClose}
        binderIdField={binderIdField}
        binderNameField={binderNameField}
      />
    </form>
  );
};

AdobeSignForm.propTypes = {
  initialState: PropTypes.shape({
    adobeSignTitle: PropTypes.string,
    adobeSignLoginId: PropTypes.string,
    adobeSignIntegrationKey: PropTypes.string,
    binderId: PropTypes.string,
    binderName: PropTypes.string,
    copyBinderId: PropTypes.string,
    copyBinderName: PropTypes.string,
    txtSignTitle: PropTypes.string,
    txtSignCompanyName: PropTypes.string,
    txtSignPartners: PropTypes.arrayOf(
      PropTypes.shape({
        txtSignName: PropTypes.string,
        txtSignMail: PropTypes.string,
      })
    ),
    txtSignMessage: PropTypes.string,
    fileSignUploadFile: PropTypes.string,
    fileSignStampFile: PropTypes.string,
    fileSignCertFile: PropTypes.string,
    datSignConcludeDate: PropTypes.string,
    selSignStatus: PropTypes.string,
    statusValueOK: PropTypes.string,
    statusValueNG: PropTypes.string,
    docLink: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
};

export default AdobeSignForm;
