//https://material-ui.com/customization/themes/
import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Yu Gothic Medium",
      "游ゴシック Medium",
      "YuGothic",
      "游ゴシック体",
      "Hiragino Kaku Gothic ProN",
      "ヒラギノ角ゴ ProN W3",
      "Osaka",
      "Yu Gothic UI",
      "MS PGothic",
      "arial",
      "sans-serif",
    ].join(","),
  },
});

export default theme;
