import React from "react";
import PropTypes from "prop-types";
import {
  fetchUtil,
  smartdbUtil,
  urlUtil,
} from "@dreamarts/baas-common-front-node";
import { useAsync } from "react-use";
import { List } from "@material-ui/core";
import RecordItemListOne from "../../components/smartdb/dialog/RecordItemListOne";
import { SDB_BRD_DETAIL } from "constants/index";
import { toErrorPage } from "pages/errors/Errors";
import { t } from "locales";

const { get } = fetchUtil;

const getBinderDetailAsync = async binderId => {
  if (!binderId) {
    return;
  }

  const body = await get(
    urlUtil.setPathParams(SDB_BRD_DETAIL, { id: binderId })
  );

  if (!body.binder) {
    toErrorPage(body);
  } else {
    return smartdbUtil.adjustResponseToArray(body.binder.item);
  }
};

export const RecordItemList = props => {
  const { handleItemSelect, binderId, keyword } = props;
  const binderItem = useAsync(() => getBinderDetailAsync(binderId), [binderId]);

  if (binderItem.loading) {
    // TODO: サイズ調整したローディングコンポーネントを使う
    return <div>{t("elements.loading.message")}</div>;
  }

  if (!binderItem.value) {
    return null;
  }

  return (
    <List>
      {binderItem.value.map((item, index) => {
        // 部品名がキーワードに部分一致しない場合は表示しない
        if (item.name && !item.name.includes(keyword)) {
          return null;
        }

        return (
          <RecordItemListOne
            sdbRecordItemName={item.name}
            sdbRecordItemId={item.id}
            key={index}
            onClick={handleItemSelect}
          />
        );
      })}
    </List>
  );
};

RecordItemList.propTypes = {
  handleItemSelect: PropTypes.func,
  binderId: PropTypes.number,
  keyword: PropTypes.string,
};
