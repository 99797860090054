import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { Button } from "@material-ui/core";
import { RecordItemChoiceList } from "compositions/smartdbdialog/RecordItemChoiceList.js";
import { t } from "locales";

export const RecordItemChoiceListDialog = props => {
  const { setData } = props;
  const { open, handleClose } = props;
  const { binderId, itemId } = props;
  const { targetItemChoiceId } = props;

  const handleItemSelect = useCallback(
    sdbItemId => {
      setData(targetItemChoiceId, sdbItemId, "");
      handleClose();
    },
    [handleClose, setData, targetItemChoiceId]
  );

  if (!binderId) {
    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {t("compositions.esign.nobinder.message")}
          </DialogTitle>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              {t("common.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          選択項目IDを選んでください
        </DialogTitle>
        <DialogContent dividers>
          <RecordItemChoiceList
            handleItemSelect={handleItemSelect}
            binderId={binderId}
            itemId={itemId}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

RecordItemChoiceListDialog.propTypes = {
  setData: PropTypes.func,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  binderId: PropTypes.number,
  itemId: PropTypes.number,
  targetItemChoiceId: PropTypes.string,
};
