import React, { useCallback } from "react";
import { useAsyncRetry } from "react-use";
import { confirmAlert } from "react-confirm-alert";

import { fetchUtil, urlUtil } from "@dreamarts/baas-common-front-node";

import Loading from "elements/loading/Loading";
import Template from "templates/list/DocuSign";
import { t } from "locales";
import { toErrorPage } from "pages/errors/Errors";

import { DOCUSIGN_API_URL, DOCUSIGN_LIST_API_URL } from "constants/index";

const { get, del } = fetchUtil;
const { setPathParams } = urlUtil;

const getListAsync = async () => {
  const body = await get(DOCUSIGN_LIST_API_URL);

  if (body.code !== "200") {
    toErrorPage(body);
  } else {
    console.log(body.data);
    return body.data;
  }
};

const List = () => {
  const list = useAsyncRetry(getListAsync, [getListAsync]);

  const deleteAsync = useCallback(
    id => {
      const doDelete = async () => {
        const url = DOCUSIGN_API_URL;
        try {
          const body = await del(setPathParams(url, { id }));
          fetchUtil.pickResponseData(body);
          list.retry();
        } catch (error) {
          toErrorPage(error);
        }
      };

      const options = {
        title: t("pages.list.delete.confirm.titie"),
        message: t("pages.list.delete.confirm.message"),
        buttons: [
          {
            label: t("pages.list.delete.confirm.yes"),
            onClick: doDelete,
          },
          {
            label: t("pages.list.delete.confirm.no"),
          },
        ],
      };
      confirmAlert(options);
    },
    [list]
  );

  if (list.loading || !list.value) {
    return <Loading />;
  }

  return <Template list={list.value} onDelete={deleteAsync} />;
};

export default List;
