import "core-js/es6/map";
import "core-js/es6/set";
import "babel-polyfill";
import "bluebird";
import "typeface-roboto";
import "react-confirm-alert/src/react-confirm-alert.css";

import React from "react";
import ReactDOM from "react-dom";
import { authUtil } from "@dreamarts/baas-common-front-node";
import * as serviceWorker from "./serviceWorker";

import App from "./App";
import { IS_STARTED_AZUREAD_LOGIN } from "pages/auth/Auth";
import { toErrorPage } from "pages/errors/Errors";
import { toTopPage } from "pages";

(async () => {
  if (!localStorage.getItem(IS_STARTED_AZUREAD_LOGIN)) {
    ReactDOM.render(<App />, document.getElementById("root"));
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
    return;
  }

  try {
    // redirect認証後に返って来るidTokenを解決させるための再認証を実施
    // idTokenが返ってくるまでには時間差があるため、その間他のページ移動が起きないように注意
    console.log("app index.js begin authUtil.authAzureAD.");

    const complete = await authUtil.authAzureAD();
    if (!complete) {
      return;
    }

    console.log("app index.js end authUtil.authAzureAD.");
    localStorage.removeItem(IS_STARTED_AZUREAD_LOGIN);

    ReactDOM.render(<App />, document.getElementById("root"));
    toTopPage();

    serviceWorker.unregister();
  } catch (e) {
    console.log("authUtil.authMe error:", e);
    // AzureAD認証失敗後に無限ループしないようにする
    localStorage.removeItem(IS_STARTED_AZUREAD_LOGIN);

    // ログイン失敗の場合はエラーページへ
    ReactDOM.render(<App />, document.getElementById("root"));
    toErrorPage(e);
    serviceWorker.unregister();
  }
})();
