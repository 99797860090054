import { history } from "@dreamarts/baas-common-front-node";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AdobeSignLogo from "./adobesign.png";
import CloudSignLogo from "./cloudsign.png";
import DocuSignLogo from "./docusign.png";
import Header from "elements/header/Header";
import {
  DOCUSIGN_LIST_PAGE_URL,
  CLOUDSIGN_LIST_PAGE_URL,
  ADOBESIGN_LIST_PAGE_URL,
} from "constants/index";
import { t } from "locales";

const useStyles = makeStyles(theme => ({
  item: {
    textAlign: "center",
  },
  container: {
    marginTop: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
  },
  imgButton: {
    textTransform: "none",
    width: 350,
    fontSize: 20,
    cursor: "pointer",
  },
  // bottomView: {
  //   height: 40,
  //   position: "absolute", //Here is the trick
  //   bottom: 0, //Here is the trick
  // },
}));

const handleDocuSign = () => {
  history.push(DOCUSIGN_LIST_PAGE_URL);
};

const handleCloudSign = () => {
  history.push(CLOUDSIGN_LIST_PAGE_URL);
};

const handleAdobeSign = () => {
  history.push(ADOBESIGN_LIST_PAGE_URL);
};

const Top = () => {
  const classes = useStyles();

  return (
    <>
      <Header title={t("tempaltes.top.title")} />
      <Container maxWidth="md" className={classes.container}>
        <Grid container alignItems="center" direction="column" spacing={2}>
          <Grid item>
            <img
              onClick={handleDocuSign}
              src={DocuSignLogo}
              className={classes.imgButton}
              alt=""
            />
          </Grid>
          <Grid item>
            <img
              onClick={handleCloudSign}
              src={CloudSignLogo}
              className={classes.imgButton}
              alt=""
            />
          </Grid>
          <Grid item>
            <img
              onClick={handleAdobeSign}
              src={AdobeSignLogo}
              className={classes.imgButton}
              alt=""
            />
          </Grid>
        </Grid>

        {/* <Grid container direction="row" justify="center" alignItems="center">
          <div className={classes.bottomView}>
            Copyright &copy; 2020 DreamArts
          </div>
        </Grid> */}
      </Container>
    </>
  );
};

Top.propTypes = {
  handleDocuSign: PropTypes.func,
  handleCloudSign: PropTypes.func,
};

export default Top;
