import PropTypes from "prop-types";
import React from "react";
import { history } from "@dreamarts/baas-common-front-node";
import { ERRORS } from "constants/proptypes/errors/errors";
import { ERROR_PAGE_URL } from "constants/index";
import Template from "templates/errors/Errors";
import { t } from "locales";

export const toErrorPage = errorObject => {
  history.push(ERROR_PAGE_URL, { errorObject });
};

export const toAppForbiddenPage = () => {
  toErrorPage(genErrorObject(t("common.app.forbidden"), "403"));
};

export const genErrorObject = (message, code = "500", level = "ERROR") => {
  return {
    code,
    errors: [
      {
        message,
        level,
      },
    ],
  };
};

const emptyErrorObj = {
  code: "500",
  errors: [
    {
      message: t("pages.errros.error.unknown.message"),
    },
  ],
};

const Errors = ({ location }) => {
  const errorObject =
    location && location.state && location.state.errorObject
      ? location.state.errorObject
      : undefined;

  const state = errorObject || emptyErrorObj;
  const code = state.code;
  const errorExist = state.errors && state.errors.length > 0;
  const message = errorExist ? state.errors[0].message : "";
  const level =
    errorExist && state.errors[0].level ? state.errors[0].level : "ERROR";

  return <Template code={code} message={message} level={level} />;
};

Errors.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      errorObject: PropTypes.shape(ERRORS),
    }),
  }),
};

export default Errors;
