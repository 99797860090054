import { Button } from "@dreamarts/da-react-ui";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AzureAD from "./azureADJa.svg";
import Header from "elements/header/Header";
import { t } from "locales";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  gridContainer: {
    marginTop: 0,
  },
  authButton: {
    textTransform: "none",
    width: 350,
    fontSize: 20,
  },
  imgButton: {
    cursor: "pointer",
  },
}));

const Auth = ({
  handleSmartDBAuth,
  handleAzureADAuth,
  handleSamlAuth,
  authSettings,
}) => {
  const classes = useStyles();

  return (
    <>
      <Header title={t("templates.auth.title")} />
      <Container maxWidth="md" className={classes.container}>
        <Grid
          container
          alignItems="center"
          direction="column"
          spacing={2}
          className={classes.gridContainer}
        >
          {authSettings.map((authSetting, index) => {
            if (!authSetting.enabled) {
              return null;
            }
            switch (authSetting.method) {
              case "local":
                return (
                  <Grid item key={authSetting.method}>
                    <Button
                      onClick={handleSmartDBAuth}
                      variant="contained"
                      color="secondary"
                      size="large"
                      className={classes.authButton}
                    >
                      {t("templates.auth.smartdb")}
                    </Button>
                  </Grid>
                );
              case "oidc":
                switch (authSetting.preset) {
                  case "AzureAD":
                    return (
                      <Grid item key={authSetting.preset}>
                        <img
                          onClick={handleAzureADAuth}
                          src={AzureAD}
                          alt={t("templates.auth.microsoft")}
                          className={classes.imgButton}
                        />
                      </Grid>
                    );
                  default:
                    return null;
                }
              case "saml":
                return (
                  <Grid item key={authSetting.method}>
                    <Button
                      onClick={handleSamlAuth}
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.authButton}
                    >
                      {authSetting.labelText}
                    </Button>
                  </Grid>
                );
              default:
                return null;
            }
          })}
        </Grid>
      </Container>
    </>
  );
};

Auth.propTypes = {
  handleSmartDBAuth: PropTypes.func,
  handleAzureADAuth: PropTypes.func,
  handleSamlAuth: PropTypes.func,
  authSettings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      enabled: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      preset: PropTypes.string,
      method: PropTypes.string.isRequired,
      samlEndPoint: PropTypes.string,
      labelText: PropTypes.string,
    })
  ),
};

export default Auth;
