import PropTypes from "prop-types";

export const ADOBE_SIGN = {
  id: PropTypes.string.isRequired,
  adobeSignTitle: PropTypes.string.isRequired,
  binderName: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedBy: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
};
