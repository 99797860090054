import React, { useState, useCallback } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { RecordItemListDialog } from "../smartdb/dialog/RecordItemListDialog";
import { RecordItemChoiceListDialog } from "../smartdb/dialog/RecordItemChoiceListDialog";
import { setInitialMap } from "./setInitialMap";

const useStyles = makeStyles(theme => ({
  sdbItemIdText: {
    flexGrow: 1,
  },
  sdbItemSelectButton: {
    marginTop: 8,
    marginLeft: 16,
    marginBottom: 22,
  },
}));

const hasCertFile = ["CloudSign", "DocuSign"];
const hasEditor = ["DocuSign"];
const hasPartnerCompany = ["CloudSign"];

export const BinderItemMappingForm = props => {
  const classes = useStyles();
  const { esignType } = props;
  const { binderId } = props;
  const { getState, setState } = props;
  const _setInitialMap = useCallback(
    () => setInitialMap(binderId, getState, setState),
    [binderId, getState, setState]
  );

  const handleSDBItemChange = useCallback(
    (sdbItemKey, subName, index) => event => {
      if (!subName) {
        setState({ [sdbItemKey]: event.target.value });
        return;
      }

      const list = getState().txtSignPartners;
      list[index][subName] = event.target.value;
      setState({ [sdbItemKey]: list });
    },
    [getState, setState]
  );

  const handleSDBItemSelect = useCallback(
    (sdbItemKey, sdbItemId, subName, index) => {
      if (!subName) {
        setState({ [sdbItemKey]: sdbItemId });
        return;
      }

      const list = getState().txtSignPartners;
      list[index][subName] = sdbItemId;
      setState({ [sdbItemKey]: list });
    },
    [getState, setState]
  );

  // 部品選択
  const [targetItemId, setTargetItemId] = useState("");
  const [targetSubName, setTargetSubName] = useState("");
  const [targetIndex, setTargetIndex] = useState(0);
  const [targetBinderId, setTargetBinderId] = useState(0);
  const [openSDBItemIdSelect, setOpenSDBItemIdSelect] = useState(false);
  const handleSDBItemIdSelectOpen = useCallback(
    (sdbItemKey, subName, index, sdbBinderId) => {
      // 部品選択ダイアログで参照するバインダの設定
      if (sdbBinderId) {
        setTargetBinderId(sdbBinderId);
      } else {
        setTargetBinderId(binderId);
      }

      if (subName) {
        setTargetSubName(subName);
        setTargetIndex(index);
      } else {
        setTargetSubName("");
      }
      setTargetItemId(sdbItemKey);
      setOpenSDBItemIdSelect(true);
    },
    [binderId]
  );
  const handleSDBItemIdSelectClose = useCallback(() => {
    setOpenSDBItemIdSelect(false);
  }, []);

  // 選択項目ID
  const [targetItemChoiceId, setTargetItemChoiceId] = useState("");
  const [openSDBItemChoiceIdSelect, setOpenSDBItemChoiceIdSelect] = useState(
    false
  );
  const handleSDBItemChoiceIdSelectOpen = useCallback(sdbItemChoiceKey => {
    setTargetItemChoiceId(sdbItemChoiceKey);
    setOpenSDBItemChoiceIdSelect(true);
  }, []);
  const handleSDBItemChoiceIdSelectClose = useCallback(() => {
    setOpenSDBItemChoiceIdSelect(false);
  }, []);

  return (
    <>
      <Grid container alignItems="center">
        <Button variant="contained" onClick={_setInitialMap}>
          連携バインダから部品IDを紐付ける
        </Button>
      </Grid>
      <Grid container alignItems="center">
        <TextField
          label="契約書タイトル"
          value={getState().txtSignTitle || ""}
          onChange={handleSDBItemChange("txtSignTitle")}
          margin="normal"
          className={classes.sdbItemIdText}
          required
          helperText="部品キー: txt_sign_title"
        />
        <Button
          variant="contained"
          onClick={() => handleSDBItemIdSelectOpen("txtSignTitle")}
          className={classes.sdbItemSelectButton}
        >
          部品選択
        </Button>
      </Grid>
      <Grid container alignItems="center">
        <TextField
          label="契約先会社名"
          value={getState().txtSignCompanyName || ""}
          onChange={handleSDBItemChange("txtSignCompanyName")}
          margin="normal"
          className={classes.sdbItemIdText}
          required
          helperText="部品キー: txt_sign_company_name"
        />
        <Button
          variant="contained"
          onClick={() => handleSDBItemIdSelectOpen("txtSignCompanyName")}
          className={classes.sdbItemSelectButton}
        >
          部品選択
        </Button>
      </Grid>
      {/* 編集担当者はドキュサイン連携のみ */}
      {hasEditor.includes(esignType) && (
        <>
          <Grid container alignItems="center">
            <TextField
              label="編集担当者名"
              value={getState().txtSignEditorName || ""}
              onChange={handleSDBItemChange("txtSignEditorName")}
              margin="normal"
              className={classes.sdbItemIdText}
              helperText="部品キー: txt_sign_editor_name"
            />
            <Button
              variant="contained"
              onClick={() => handleSDBItemIdSelectOpen("txtSignEditorName")}
              className={classes.sdbItemSelectButton}
            >
              部品選択
            </Button>
          </Grid>
          <Grid container alignItems="center">
            <TextField
              label="編集担当者メールアドレス"
              value={getState().txtSignEditorMail || ""}
              onChange={handleSDBItemChange("txtSignEditorMail")}
              margin="normal"
              className={classes.sdbItemIdText}
              helperText="部品キー: txt_sign_editor_mail"
            />
            <Button
              variant="contained"
              onClick={() => handleSDBItemIdSelectOpen("txtSignEditorMail")}
              className={classes.sdbItemSelectButton}
            >
              部品選択
            </Button>
          </Grid>
        </>
      )}
      {getState().txtSignPartners.map((value, index) => {
        return (
          <Grid container alignItems="center" key={index}>
            <TextField
              label={`署名担当者名${index + 1}`}
              value={value.txtSignName || ""}
              onChange={handleSDBItemChange(
                "txtSignPartners",
                "txtSignName",
                index
              )}
              margin="normal"
              className={classes.sdbItemIdText}
              required={index === 0} // 署名担当者1人目は必須入力項目とする
              helperText={`部品キー: txt_sign_name_${index + 1}`}
            />
            <Button
              variant="contained"
              onClick={() =>
                handleSDBItemIdSelectOpen(
                  "txtSignPartners",
                  "txtSignName",
                  index
                )
              }
              className={classes.sdbItemSelectButton}
            >
              部品選択
            </Button>
          </Grid>
        );
      })}
      {getState().txtSignPartners.map((value, index) => {
        return (
          <Grid container alignItems="center" key={index}>
            <TextField
              label={`署名担当者メールアドレス${index + 1}`}
              value={value.txtSignMail || ""}
              onChange={handleSDBItemChange(
                "txtSignPartners",
                "txtSignMail",
                index
              )}
              margin="normal"
              className={classes.sdbItemIdText}
              required={index === 0} // 署名担当者1人目は必須入力項目とする
              helperText={`部品キー: txt_sign_mail_${index + 1}`}
            />
            <Button
              variant="contained"
              onClick={() =>
                handleSDBItemIdSelectOpen(
                  "txtSignPartners",
                  "txtSignMail",
                  index
                )
              }
              className={classes.sdbItemSelectButton}
            >
              部品選択
            </Button>
          </Grid>
        );
      })}
      {hasPartnerCompany.includes(esignType) &&
        getState().txtSignPartners.map((value, index) => {
          return (
            <Grid container alignItems="center" key={index}>
              <TextField
                label={`署名担当者所属会社名${index + 1}`}
                value={value.txtSignAnotherCompanyName || ""}
                onChange={handleSDBItemChange(
                  "txtSignPartners",
                  "txtSignAnotherCompanyName",
                  index
                )}
                margin="normal"
                className={classes.sdbItemIdText}
                helperText={`部品キー: txt_sign_another_company_name_${index +
                  1}`}
              />
              <Button
                variant="contained"
                onClick={() =>
                  handleSDBItemIdSelectOpen(
                    "txtSignPartners",
                    "txtSignAnotherCompanyName",
                    index
                  )
                }
                className={classes.sdbItemSelectButton}
              >
                部品選択
              </Button>
            </Grid>
          );
        })}
      <Grid container alignItems="center">
        <TextField
          label="送信メッセージ"
          value={getState().txtSignMessage || ""}
          onChange={handleSDBItemChange("txtSignMessage")}
          margin="normal"
          className={classes.sdbItemIdText}
          required
          helperText="部品キー: txt_sign_message"
        />
        <Button
          variant="contained"
          onClick={() => handleSDBItemIdSelectOpen("txtSignMessage")}
          className={classes.sdbItemSelectButton}
        >
          部品選択
        </Button>
      </Grid>
      <Grid container alignItems="center">
        <TextField
          label="押印前の契約書"
          value={getState().fileSignUploadFile || ""}
          onChange={handleSDBItemChange("fileSignUploadFile")}
          margin="normal"
          className={classes.sdbItemIdText}
          required
          helperText="部品キー: file_sign_upload_file"
        />
        <Button
          variant="contained"
          onClick={() => handleSDBItemIdSelectOpen("fileSignUploadFile")}
          className={classes.sdbItemSelectButton}
        >
          部品選択
        </Button>
      </Grid>
      <Grid container alignItems="center">
        <TextField
          label="押印済の契約書"
          value={getState().fileSignStampFile || ""}
          onChange={handleSDBItemChange("fileSignStampFile")}
          margin="normal"
          className={classes.sdbItemIdText}
          required
          helperText="部品キー: file_sign_stamp_file"
        />
        <Button
          variant="contained"
          onClick={() => handleSDBItemIdSelectOpen("fileSignStampFile")}
          className={classes.sdbItemSelectButton}
        >
          部品選択
        </Button>
      </Grid>
      {/* 合意締結証明書を持つ場合は入力項目として表示する */}
      {hasCertFile.includes(esignType) && (
        <Grid container alignItems="center">
          <TextField
            label="合意締結証明書"
            value={getState().fileSignCertFile || ""}
            onChange={handleSDBItemChange("fileSignCertFile")}
            margin="normal"
            className={classes.sdbItemIdText}
            required
            helperText="部品キー: file_sign_cert_file"
          />
          <Button
            variant="contained"
            onClick={() => handleSDBItemIdSelectOpen("fileSignCertFile")}
            className={classes.sdbItemSelectButton}
          >
            部品選択
          </Button>
        </Grid>
      )}
      <Grid container alignItems="center">
        <TextField
          label="契約締結日"
          value={getState().datSignConcludeDate || ""}
          onChange={handleSDBItemChange("datSignConcludeDate")}
          margin="normal"
          className={classes.sdbItemIdText}
          required
          helperText="部品キー: dat_sign_conclude_date"
        />
        <Button
          variant="contained"
          onClick={() => handleSDBItemIdSelectOpen("datSignConcludeDate")}
          className={classes.sdbItemSelectButton}
        >
          部品選択
        </Button>
      </Grid>
      <Grid container alignItems="center">
        <TextField
          label="押印ステータス"
          value={getState().selSignStatus || ""}
          onChange={handleSDBItemChange("selSignStatus")}
          margin="normal"
          className={classes.sdbItemIdText}
          required
          helperText="部品キー: sel_sign_status"
        />
        <Button
          variant="contained"
          onClick={() => handleSDBItemIdSelectOpen("selSignStatus")}
          className={classes.sdbItemSelectButton}
        >
          部品選択
        </Button>
      </Grid>
      {/* 選択項目IDのフォームは押印ステータス部品の部品IDが設定されている場合表示する */}
      {getState().selSignStatus && (
        <>
          <Grid container alignItems="center">
            <TextField
              label="押印ステータス 選択項目ID（先方押印済）"
              value={getState().statusValueOK || ""}
              onChange={handleSDBItemChange("statusValueOK")}
              margin="normal"
              className={classes.sdbItemIdText}
              required
              helperText="部品キー: sel_sign_status"
            />
            <Button
              variant="contained"
              onClick={() => handleSDBItemChoiceIdSelectOpen("statusValueOK")}
              className={classes.sdbItemSelectButton}
            >
              選択項目ID選択
            </Button>
          </Grid>
          <Grid container alignItems="center">
            <TextField
              label="押印ステータス 選択項目ID（先方押印却下）"
              value={getState().statusValueNG || ""}
              onChange={handleSDBItemChange("statusValueNG")}
              margin="normal"
              className={classes.sdbItemIdText}
              required
              helperText="部品キー: sel_sign_status"
            />
            <Button
              variant="contained"
              onClick={() => handleSDBItemChoiceIdSelectOpen("statusValueNG")}
              className={classes.sdbItemSelectButton}
            >
              選択項目ID選択
            </Button>
          </Grid>
        </>
      )}
      {/* コピー先連携バインダのバインダIDが設定されている場合表示する */}
      {getState().copyBinderId && (
        <Grid container alignItems="center">
          <TextField
            label="コピー元バインダへの文書リンク"
            value={getState().docLink || ""}
            onChange={handleSDBItemChange("docLink")}
            margin="normal"
            className={classes.sdbItemIdText}
            helperText="部品キー: link_sign_record"
          />
          <Button
            variant="contained"
            onClick={() =>
              handleSDBItemIdSelectOpen(
                "docLink",
                "",
                0,
                getState().copyBinderId
              )
            }
            className={classes.sdbItemSelectButton}
          >
            部品選択
          </Button>
        </Grid>
      )}
      {/* ダイアログ（部品選択） */}
      <RecordItemListDialog
        setData={handleSDBItemSelect}
        open={openSDBItemIdSelect}
        handleClose={handleSDBItemIdSelectClose}
        binderId={targetBinderId}
        targetItemId={targetItemId}
        targetSubName={targetSubName}
        targetIndex={targetIndex}
      />
      {/* ダイアログ（選択項目ID） */}
      <RecordItemChoiceListDialog
        setData={handleSDBItemSelect}
        open={openSDBItemChoiceIdSelect}
        handleClose={handleSDBItemChoiceIdSelectClose}
        binderId={binderId}
        itemId={getState().selSignStatus}
        targetItemChoiceId={targetItemChoiceId}
      />
    </>
  );
};

BinderItemMappingForm.propTypes = {
  esignType: PropTypes.string,
  binderId: PropTypes.number,
  getState: PropTypes.func,
  setState: PropTypes.func,
};

export default BinderItemMappingForm;
