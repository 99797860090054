import PropTypes from "prop-types";

export const ERROR = {
  level: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export const ERRORS = {
  code: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape(ERROR)),
};
