import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useCallback, useRef } from "react";

import { useSnackbar } from "@dreamarts/da-react-ui";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import { useGetSetState, useAsync } from "react-use";
import { fetchUtil, urlUtil } from "@dreamarts/baas-common-front-node";
import { createMuiTheme } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { BinderListDialogs } from "./BinderListDialog";
import { SubMenuDialog } from "./SubMenuDialog";
import { BinderItemMappingForm } from "./BinderItemMappingForm";
import { t } from "locales";

import {
  DOCUSIGN_JWT_CONSENT_API_URL,
  DOCUSIGN_LIST_PAGE_URL,
} from "constants/index";

const { get } = fetchUtil;

const useStyles = makeStyles(theme => ({
  binderIdText: {
    flexGrow: 1,
  },
  binderSelectButton: {
    marginTop: 8,
    marginLeft: 16,
  },
  copyBinderSelectButton: {
    marginBottom: 22,
  },
  consentButton: {
    marginBottom: 20,
  },
  submitButton: {
    marginTop: 16,
  },
}));

const theme = createMuiTheme({
  palette: {
    action: {
      disabledBackground: "#4caf50",
      disabled: "white",
    },
  },
});

//** JWT認証を有効化しているか（アクセストークン取得の成否）を取得する */
const getIsValidJWT = async binderId => {
  if (!binderId) {
    return false;
  }

  try {
    const isValidJWT = await get(
      urlUtil.setPathParams(DOCUSIGN_JWT_CONSENT_API_URL, { binderId })
    );
    return fetchUtil.pickResponseData(isValidJWT);
  } catch (error) {
    return false;
  }
};

const DocuSignForm = ({ initialState, onSubmit }) => {
  const formRef = useRef();

  const [getState, setState] = useGetSetState(initialState);
  const classes = useStyles();

  const { enqueueSuccessSnackbar } = useSnackbar();

  const handleChange = useCallback(
    name => event => {
      setState({ [name]: event.target.value });
    },
    [setState]
  );

  const handleSelect = useCallback(
    (name, id) => {
      setState({ [name]: id });
    },
    [setState]
  );

  const _onSubmit = useCallback(
    () => {
      if (formRef.current.checkValidity()) {
        onSubmit && onSubmit(getState());
        enqueueSuccessSnackbar("DocuSign定義を保存しました。");
      } else {
        formRef.current.reportValidity();
      }
    },
    [getState, onSubmit, enqueueSuccessSnackbar]
  );

  // for バインダリスト
  const [binderIdField, setBinderIdField] = React.useState("binderId");
  const [binderNameField, setBinderNameField] = React.useState("binderName");
  const [openBrdList, setOpenBrdList] = React.useState(false);
  const handleBinderListOpen = useCallback(() => {
    setBinderIdField("binderId");
    setBinderNameField("binderName");
    setOpenBrdList(true);
  }, []);
  const handleBinderListClose = useCallback(id => {
    setOpenBrdList(false);
  }, []);
  const handleCopyBinderListOpen = useCallback(() => {
    setBinderIdField("copyBinderId");
    setBinderNameField("copyBinderName");
    setOpenBrdList(true);
  }, []);

  const isValidJWT = useAsync(async () => {
    const response = await getIsValidJWT(getState().binderId);
    return response;
  }, []);

  const jwtConsent = useCallback(
    () => {
      let baseUrl = "";
      if (getState().docuSignBase === "sandbox") {
        baseUrl = "https://account-d.docusign.com/oauth/auth";
      } else {
        baseUrl = "https://account.docusign.com/oauth/auth";
      }
      const responseType = "response_type=code";
      const scope = "scope=signature%20impersonation";
      const clientId = `client_id=${getState().docuSignIntegratorKey}`;
      const redirectUri = `redirect_uri=https://${
        window.location.host
      }/docuSign`;

      window.open(
        `${baseUrl}?${responseType}&${scope}&${clientId}&${redirectUri}`
      );
    },
    [getState]
  );

  const webhookUrl = "https://" + window.location.host + "/api/docuSign/send";

  return (
    <form ref={formRef}>
      <SubMenuDialog listUrl={DOCUSIGN_LIST_PAGE_URL} />
      {getState().id && !isValidJWT.value && (
        <Button
          variant="contained"
          onClick={jwtConsent}
          className={classes.consentButton}
        >
          連携の有効化
        </Button>
      )}
      {getState().id && isValidJWT.value && (
        <MuiThemeProvider theme={theme}>
          <Button
            variant="contained"
            onClick={jwtConsent}
            className={classes.consentButton}
            disabled
          >
            連携の有効化済み
          </Button>
        </MuiThemeProvider>
      )}
      <Grid container direction="column">
        <FormControl>
          <InputLabel id="demo-simple-select-label">接続先</InputLabel>
          <Select
            labelid="demo-simple-select-label"
            id="demo-simple-select"
            value={getState().docuSignBase}
            onChange={handleChange("docuSignBase")}
          >
            <MenuItem value={"sandbox"}>Sandbox環境</MenuItem>
            <MenuItem value={"product"}>本番環境</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="連携定義のタイトル"
          value={getState().docuSignTitle}
          onChange={handleChange("docuSignTitle")}
          margin="normal"
          required
        />
        <TextField
          label="DocuSignインテグレーションキー"
          value={getState().docuSignIntegratorKey}
          onChange={handleChange("docuSignIntegratorKey")}
          margin="normal"
          required
        />
        <TextField
          label="DocuSignAPIユーザ名"
          value={getState().docuSignUserId}
          onChange={handleChange("docuSignUserId")}
          margin="normal"
          required
        />
        {!getState().id && (
          <TextField
            label="DocuSignサービスインテグレーションRSA秘密鍵"
            onChange={handleChange("docuSignSecretKey")}
            margin="normal"
            required
            multiline
            variant="outlined"
            rows={5}
            inputProps={{ wrap: "off" }}
          />
        )}
        {getState().id && (
          <TextField
            label="DocuSignサービスインテグレーションRSA秘密鍵"
            value="************************"
            margin="normal"
            disabled
            helperText="秘密鍵は編集できません。"
          />
        )}
        <FormControl>
          <InputLabel id="demo-simple-select-label">
            エンベロープ作成後の動作
          </InputLabel>
          <Select
            labelid="demo-simple-select-label"
            id="demo-simple-select"
            value={getState().shouldSendEnvelope}
            onChange={handleChange("shouldSendEnvelope")}
          >
            <MenuItem value={true}>署名依頼メールを送信する</MenuItem>
            <MenuItem value={false}>ドラフト作成に留める</MenuItem>
          </Select>
        </FormControl>
        <Grid container alignItems="center">
          <TextField
            label="連携バインダID"
            value={getState().binderId || ""}
            onChange={handleChange("binderId")}
            margin="normal"
            className={classes.binderIdText}
            required
          />
          <Button
            variant="contained"
            onClick={handleBinderListOpen}
            className={classes.binderSelectButton}
          >
            バインダ選択
          </Button>
        </Grid>
        <TextField
          label="連携バインダ名"
          value={getState().binderName || ""}
          onChange={handleChange("binderName")}
          margin="normal"
        />
        <Grid container alignItems="center">
          <TextField
            label="コピー先連携バインダID"
            value={getState().copyBinderId || ""}
            onChange={handleChange("copyBinderId")}
            margin="normal"
            className={classes.binderIdText}
            helperText="確定した契約書をコピーしたいバインダを指定してください。"
          />
          <Button
            variant="contained"
            onClick={handleCopyBinderListOpen}
            className={`${classes.binderSelectButton} ${
              classes.copyBinderSelectButton
            }`}
          >
            バインダ選択
          </Button>
        </Grid>
        <TextField
          label="コピー先連携バインダ名"
          value={getState().copyBinderName || ""}
          onChange={handleChange("copyBinderName")}
          margin="normal"
        />
        <BinderItemMappingForm
          esignType="DocuSign"
          binderId={getState().binderId}
          getState={getState}
          setState={setState}
        />
        {getState().id && (
          <>
            <TextField
              label="WebhookURL"
              value={webhookUrl}
              margin="normal"
              disabled
              helperText="SmartDBプロセスのWebhookURLに設定してください。"
            />
            <TextField
              label="IPアドレス"
              value={getState().ipAddress}
              margin="normal"
              disabled
              helperText="IPアドレスを制御する場合、設定してください。"
            />
          </>
        )}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={_onSubmit}
        className={classes.submitButton}
      >
        {t("common.save")}
      </Button>
      {/* ダイアログ */}
      <BinderListDialogs
        setData={handleSelect}
        open={openBrdList}
        handleClickOpen={handleBinderListOpen}
        handleClose={handleBinderListClose}
        binderIdField={binderIdField}
        binderNameField={binderNameField}
      />
    </form>
  );
};

DocuSignForm.propTypes = {
  initialState: PropTypes.shape({
    docuSignBase: PropTypes.string,
    docuSignTitle: PropTypes.string,
    docuSignIntegratorKey: PropTypes.string,
    docuSignUserId: PropTypes.string,
    docuSignSecretKey: PropTypes.string,
    shouldSendEnvelope: PropTypes.bool,
    binderId: PropTypes.string,
    binderName: PropTypes.string,
    copyBinderId: PropTypes.string,
    copyBinderName: PropTypes.string,
    txtSignTitle: PropTypes.string,
    txtSignCompanyName: PropTypes.string,
    txtSignPartners: PropTypes.arrayOf(
      PropTypes.shape({
        txtSignName: PropTypes.string,
        txtSignMail: PropTypes.string,
      })
    ),
    txtSignMessage: PropTypes.string,
    fileSignUploadFile: PropTypes.string,
    fileSignStampFile: PropTypes.string,
    fileSignCertFile: PropTypes.string,
    datSignConcludeDate: PropTypes.string,
    selSignStatus: PropTypes.string,
    statusValueOK: PropTypes.string,
    statusValueNG: PropTypes.string,
    docLink: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
};

export default DocuSignForm;
