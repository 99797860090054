import { Button, OutlinedTextField, useSnackbar } from "@dreamarts/da-react-ui";
import React, { useCallback, useState } from "react";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { t } from "locales";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginTop: 0,
  },
}));

export const SmartDBAccessTokenForm = ({ initialState, onSubmit }) => {
  const classes = useStyles();

  const [state, setState] = useState(initialState);
  const [processing, setProcessing] = useState(false);

  const { enqueueSuccessSnackbar } = useSnackbar();

  const setAccessToken = useCallback(
    value => {
      setState({ accessToken: value });
    },
    [setState]
  );

  const _onSubmit = useCallback(
    async () => {
      setProcessing(true);
      await onSubmit(state);
      setProcessing(false);
      enqueueSuccessSnackbar(
        t("components.smartdb.accesstokenform.accesstoken.completed")
      );
    },
    [onSubmit, state, setProcessing, enqueueSuccessSnackbar]
  );

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      className={classes.gridContainer}
    >
      <Grid item>
        <OutlinedTextField
          value={state.accessToken}
          onChange={setAccessToken}
          title={t("components.smartdb.accesstokenform.accesstoken")}
          placeholder={t("components.smartdb.accesstokenform.accesstoken")}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          type="button"
          disabled={processing}
          onClick={_onSubmit}
        >
          {t("common.save")}
        </Button>
      </Grid>
    </Grid>
  );
};

SmartDBAccessTokenForm.propTypes = {
  initialState: PropTypes.shape({
    accessToken: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
};
