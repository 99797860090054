import { Button, OutlinedTextField } from "@dreamarts/da-react-ui";
import React, { useCallback, useState } from "react";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { t } from "locales";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginTop: 0,
  },
}));

export const SmartDBLoginForm = ({ onSubmit }) => {
  const classes = useStyles();

  const [loginid, setLoginid] = useState("");
  const [password, setPassword] = useState("");

  const _setLoginid = useCallback(
    value => {
      setLoginid(value);
    },
    [setLoginid]
  );
  const _setPassword = useCallback(
    value => {
      setPassword(value);
    },
    [setPassword]
  );

  const _onSubmit = useCallback(
    () => {
      onSubmit(loginid, password);
    },
    [loginid, password, onSubmit]
  );

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      className={classes.gridContainer}
    >
      <Grid item>
        <OutlinedTextField
          value={loginid}
          onChange={_setLoginid}
          title={t("components.smartdb.loginform.userid")}
          placeholder={t("components.smartdb.loginform.userid")}
        />
      </Grid>
      <Grid item>
        <OutlinedTextField
          value={password}
          type="password"
          onChange={_setPassword}
          title={t("components.smartdb.loginform.password")}
          placeholder={t("components.smartdb.loginform.password")}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={_onSubmit}
        >
          {t("components.smartdb.loginform.submit")}
        </Button>
      </Grid>
    </Grid>
  );
};

SmartDBLoginForm.propTypes = {
  onSubmit: PropTypes.func,
};
