import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import React, { useState, useCallback } from "react";
import { Button } from "@material-ui/core";
import { RecordItemList } from "compositions/smartdbdialog/RecordItemList.js";
import { t } from "locales";

const useStyles = makeStyles(theme => ({
  keywordTextField: {
    flexGrow: 1,
  },
  searchButton: {
    marginLeft: 16,
  },
}));

export const RecordItemListDialog = props => {
  const { setData } = props;
  const { open, handleClose } = props;
  const { binderId } = props;
  const { targetItemId, targetSubName, targetIndex } = props;

  const classes = useStyles();

  const [keyword, setKeyword] = useState("");
  const [textFieldValue, setTextFieldValue] = useState("");
  const handleStartSearch = useCallback(() => setKeyword(textFieldValue), [
    textFieldValue,
  ]);

  const handleChangeTextField = useCallback(
    e => setTextFieldValue(e.target.value),
    []
  );

  const handleKeyPressTextField = useCallback(
    e => {
      if (e.key === "Enter") {
        handleStartSearch();
      }
    },
    [handleStartSearch]
  );

  const handleItemSelect = useCallback(
    sdbItemId => {
      setData(targetItemId, sdbItemId, targetSubName, targetIndex);
      handleClose();
    },
    [handleClose, setData, targetIndex, targetItemId, targetSubName]
  );

  if (!binderId) {
    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {t("compositions.esign.nobinder.message")}
          </DialogTitle>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              {t("common.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          連携先部品を選んでください
        </DialogTitle>
        <DialogContent dividers>
          <Grid container alignItems="center">
            <TextField
              label="キーワード"
              value={textFieldValue}
              onKeyPress={handleKeyPressTextField}
              onChange={handleChangeTextField}
              className={classes.keywordTextField}
              required
            />
            <Button
              variant="contained"
              onClick={handleStartSearch}
              className={classes.searchButton}
            >
              {t("common.search")}
            </Button>
          </Grid>
          <RecordItemList
            handleItemSelect={handleItemSelect}
            binderId={binderId}
            keyword={keyword}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

RecordItemListDialog.propTypes = {
  setData: PropTypes.func,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  binderId: PropTypes.number,
  targetItemId: PropTypes.string,
  targetSubName: PropTypes.string,
  targetIndex: PropTypes.number,
};
