import { t } from "locales";

const ACCESSTOKEN_ALERT_OPTIONS = {
  title: t("constants.option.accesstoken.alert.title"),
  message: t("constants.option.accesstoken.alert.message"),
  buttons: [
    {
      label: t("common.close"),
    },
  ],
};

export default ACCESSTOKEN_ALERT_OPTIONS;
